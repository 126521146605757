import React, { useState, useContext, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";
import { booleanContext } from "../../App";
import { Constants } from "./Constants";

function Header(props) {
  const selectedCountry = useContext(booleanContext);
  const [show, setShow] = useState(false);
  const [countrySelected, setCountrySelected] = useState("");

  useEffect(() => {
    switch (selectedCountry) {
      case "AU":
        setCountrySelected("Australia");
        break;
      case "NZ":
        setCountrySelected("New Zealand");
        break;
      case "US":
        setCountrySelected("United States of America");
        break;
      case "GB":
        setCountrySelected("United Kingdom");
        break;
      case "ES":
        setCountrySelected("Spain");
        break;
      case "PT":
        setCountrySelected("Portugal");
        break;
      case "FR":
        setCountrySelected("France");
        break;
      case "DE":
        setCountrySelected("Germany");
        break;
      case "IT":
        setCountrySelected("Italy");
        break;
      case "HU":
        setCountrySelected("Hungary");
        break;

      default:
        break;
    }
  }, [selectedCountry]);

  const handleCountrySwitch = (e) => {
    switch (e.target.innerText) {
      case "Australia":
        assignCountryCode(e.target.innerText, "AU");
        break;
      case "New Zealand":
        assignCountryCode(e.target.innerText, "NZ");
        break;
      case "United States of America":
        assignCountryCode(e.target.innerText, "US");
        break;
      case "United Kingdom":
        assignCountryCode(e.target.innerText, "GB");
        break;
      case "Spain":
        assignCountryCode(e.target.innerText, "ES");
        break;
      case "Portugal":
        assignCountryCode(e.target.innerText, "PT");
        break;
      case "France":
        assignCountryCode(e.target.innerText, "FR");
        break;
      case "Germany":
        assignCountryCode(e.target.innerText, "DE");
        break;
      case "Italy":
        assignCountryCode(e.target.innerText, "IT");
        break;
      case "Hungary":
        assignCountryCode(e.target.innerText, "HU");
        break;

      default:
        break;
    }
  };

  function assignCountryCode(country, code) {
    localStorage.setItem("country_selected", code);
    setCountrySelected(country);
    props.getSelectedCountry(code);
  }

  const showDropdown = (e) => {
    setShow(!show);
  };

  const hideDropdown = (e) => {
    setShow(false);
  };
  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.Corteva.com/" target="_blank">
          <Image
            src={Logo}
            alt="Corteva logo"
            className="p-2"
            width="220px"
            height="auto"
          />
        </Navbar.Brand>
        <div className="flexarea"></div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavDropdown
              title={countrySelected}
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              id="collasible-nav-dropdown"
            >
              {Constants.countriesInternalForm.map((country) => (
                <NavDropdown.Item onClick={handleCountrySwitch} key={country}>
                  <div className="d-inline">{country}</div>
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
