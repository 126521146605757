import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useState } from "react";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import IntakeForm from "./components/IntakeForm";
import IntakeFormAPAC from "./components/IntakeFormAPAC";
import "./App.scss";

export const booleanContext = React.createContext();

function App() {
  const country = localStorage.getItem("country_selected");
  const [selectedCountry, setSelectedCountry] = useState(
    country === null ? "US" : country
  );

  const getSelectedCountry = (countryVal) => {
    setSelectedCountry(countryVal);
  };

  return (
    <div>
      <booleanContext.Provider value={selectedCountry}>
        <Router>
          <Header getSelectedCountry={getSelectedCountry} />
          <Switch>
            <Route
              path="/"
              exact
              component={
                ["AU", "NZ"].includes(selectedCountry)
                  ? IntakeFormAPAC
                  : IntakeForm
              }
            />
          </Switch>
          <Footer />
        </Router>
      </booleanContext.Provider>
    </div>
  );
}

export default App;
